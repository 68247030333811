import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import NavBar from '../../Components/Navbar';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { eventId } from '../../config';

// Translations
import { withTranslation } from 'react-i18next';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '70%',
    },
  },
  headings: {
    padding: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '80%',
  },
  formControlButton: {
    margin: theme.spacing(2),
    minWidth: 120,
    width: '50%',
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
});

class ConfirmationCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      confirmationCode: '',
      passwordResetRequest: false,
      passwordReset: false,
      newPassword: '',
      errorMessage: '',
      error: false,
    };
  }

  handleChange(event, inputIdentifier) {
    this.setState({ [inputIdentifier]: event.target.value });
  }

  requestPasswordReset = () => {
    if (this.state.username) {
      Auth.forgotPassword(this.state.username.toLowerCase())
        .then((data) => {
          this.setState({
            passwordResetRequest: true,
            error: false,
          });
        })
        .catch((err) => {
          if (err.code === 'InvalidParameterException') {
            this.setState({
              errorMessage:
                'Unable to reset your password without a confirmed email address. Please verify your email address using the link sent to your inbox or contact support to reset your account',
              error: true,
            });
          } else if (err.code === 'UserNotFoundExceptio') {
            this.setState({
              errorMessage: 'Unknown Email',
              error: true,
            });
          } else {
            this.setState({
              errorMessage: 'Unknown Email',
              error: true,
            });
          }
        });
    } else {
      this.setState({
        errorMessage: 'Enter a Email',
        error: true,
      });
    }
  };

  passwordReset = () => {
    Auth.forgotPasswordSubmit(
      this.state.username.toLowerCase(),
      this.state.confirmationCode,
      this.state.newPassword
    )
      .then((data) => {
        this.setState({
          passwordReset: true,
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { classes } = this.props;

    if (this.state.passwordReset) {
      return (
        <div>
          <NavBar />
          <div className={classes.grid}>
            <Grid
              style={{ paddingTop: '85px' }}
              container
              layout={'row'}
              spacing={0}
              justify="center"
            >
              <Grid item xs={12} sm={3} md={3}>
                <Paper className={classes.paper}>
                  <Typography variant="h6" className={classes.title}>
                    {this.props.t('registration.passwordWasReset')}
                  </Typography>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      component={Link}
                      to={'/registration'}
                      className={classes.button}
                    >
                      {this.props.t('landing.signIn')}
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    } else if (this.state.passwordResetRequest) {
      return (
        <div>
          <NavBar />
          <div className={classes.grid}>
            <Grid
              style={{ paddingTop: '85px' }}
              container
              layout={'row'}
              spacing={0}
              justify="center"
            >
              <Grid item xs={12} sm={3} md={3}>
                <Paper className={classes.paper}>
                  <Typography variant="h6" className={classes.title}>
                    {this.props.t('registration.checkEmailConfirmation')}
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <TextField
                      onChange={(event) => this.handleChange(event, 'username')}
                      value={this.state.username}
                      label="Email"
                      disabled
                      variant="filled"
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      onChange={(event) =>
                        this.handleChange(event, 'confirmationCode')
                      }
                      value={this.state.confirmationCode}
                      label={this.props.t('registration.confirmationCode')}
                      variant="filled"
                    />
                  </FormControl>
                  <FormControl className={classes.formControl}>
                    <TextField
                      onChange={(event) =>
                        this.handleChange(event, 'newPassword')
                      }
                      value={this.state.newPassword}
                      type="password"
                      label={this.props.t('registration.newPassword')}
                      variant="filled"
                    />
                  </FormControl>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      variant="contained"
                      onClick={this.passwordReset}
                      className={classes.button}
                    >
                      {this.props.t('registration.confirmNewPassword')}
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <NavBar />
          <div className={classes.grid}>
            <Grid
              style={{ paddingTop: '85px' }}
              container
              layout={'row'}
              spacing={0}
              justify="center"
            >
              <Grid item xs={12} sm={3} md={3}>
                <Paper className={classes.paper}>
                  <Typography variant="h6" className={classes.title}>
                    {this.props.t('registration.resetPassword')}
                  </Typography>
                  <FormControl className={classes.formControl}>
                    <TextField
                      onChange={(event) => this.handleChange(event, 'username')}
                      value={this.state.username}
                      label={this.props.t('registration.email')}
                      error={this.state.error}
                      helperText={this.state.errorMessage}
                      variant="filled"
                    />
                  </FormControl>
                  <FormControl className={classes.formControlButton}>
                    <Button
                      className={classes.button}
                      variant="contained"
                      onClick={this.requestPasswordReset}
                    >
                      {this.props.t('registration.reset')}
                    </Button>
                  </FormControl>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      );
    }
  }
}

export default withStyles(useStyles)(withTranslation()(ConfirmationCode));
