import React, { Component } from 'react';
import axios from 'axios';
import { Tabs, Tab, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import Box from '@material-ui/core/Box';
// import Chip from '@material-ui/core/Chip';
import NavBar from '../../Components/Navbar';
import ToggleButton from '@material-ui/lab/ToggleButton';
import CheckIcon from '@material-ui/icons/Check';
import { Formik, Form, Field, useField, FieldArray } from 'formik';
import Divider from '@material-ui/core/Divider';
import { NativeSelect } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Custom
import { EmailTemplate, FormatObject } from './EmailTemplate';
import KeywordLookupField from './KeywordLookupField';

import { eventId, accountId } from '../../config';

import { Img } from 'react-image';
import { FileUploader } from '../../Utilities/fileUploader'; //LIKELY WILL NEED FOR VERSION 2
import PersonIcon from '@material-ui/icons/Person'; //LIKELY WILL NEED FOR VERSION 2

import { connect } from 'react-redux';

// Translations
import { withTranslation } from 'react-i18next';

const useStyles = (theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(2),
      width: '100%',
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  button: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      boxShadow: 'none',
    },
  },
  toggleButton: {
    '& .Mui-selected': {
      backgroundColor: theme.palette.primary.light,
    },
    '& .Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  acceptButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    width: '40%',
    marginLeft: '5%',
    marginRight: '5%',
    fontSize: '1rem',
  },
  closeButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    width: '40%',
    marginLeft: '5%',
    marginRight: '5%',
    fontSize: '1rem',
  },
  gridContainer: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  navButton: {
    margin: theme.spacing(1),
  },
  submitButton: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    // color: ,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      // borderColor: '#0062cc',
      // boxShadow: 'none',
    },
  },
  infoField: {
    '&.MuiTextField-root': {
      margin: 0,
      marginTop: theme.spacing(0.75),
      marginBottom: theme.spacing(0.75),
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  selectField: {
    '&.MuiInputBase-fullWidth': {
      margin: 0,
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      '&.MuiNativeSelect-select': {
        padding: theme.spacing(5),
      },
      '&.MuiSelect-select': {
        padding: theme.spacing(5),
      },
    },
  },
  divider: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  toggleText: {
    marginLeft: '16px',
    alignSelf: 'center',
  },
  error: {
    color: 'red',
    fontFamily: 'Roboto',
  },
  tabNoCursor: {
    cursor: 'default',
  },
});

class AbstractSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      existingRegistrant: null,
      emailNotRegistered: false,
      typeAsObject: {},
      typesDetailed: {},
      topicMap: ['Sample1', 'Sample2'],
      publicViewOptions: [
        'Yes - Approved',
        'No - Applying for Approval',
        'No',
        'Other',
      ],
      file: null,
      abstractUrl: '',
      submitterIsAuthor: false,
      presenterUnknown: false,
      submitterIsPresenter: false,
      authorCount: 0,
      presenterCount: 0,
      abstractFields: [[{}], [{}], [{}]],
      checkboxError: null,
    };
  }
  componentDidMount() {
    axios({
      method: 'get',
      url: 'forms/' + eventId,
      // headers: { idtoken: data.idToken.jwtToken },
      params: { showId: eventId },
    })
      .then((response) => {
        let formObject = response.data.data;
        let abstractSubmission = formObject.filter(
          (item) => item.type === 'abstract_submission'
        )[0];
        abstractSubmission.fields = abstractSubmission.fields.sort(function (
          a,
          b
        ) {
          return a.field_id - b.field_id;
        });
        let tab2Fields = abstractSubmission.fields
          .filter((field) => field.section === 'type')
          .filter((field) => field.is_enabled === true);
        let tab3Fields = abstractSubmission.fields
          .filter((field) => field.section === 'author_fields')
          .filter((field) => field.is_enabled === true);
        let tab4Fields = abstractSubmission.fields
          .filter((field) => field.section === 'presenter_fields')
          .filter((field) => field.is_enabled === true);
        const typesAsObject = {};
        const typesDetailed = {};
        tab2Fields.forEach((field) => {
          typesAsObject[field.name] = false; // if type option is removed from attendee options, set this to true
          typesDetailed[field.name] = field;
        });
        // console.log(typesAsObject)
        // console.log(typesDetailed)
        // console.log(tab2Fields)
        // console.log(tab3Fields)
        // console.log(tab4Fields)
        this.setState({
          abstractFields: [tab2Fields, tab3Fields, tab4Fields],
          typeAsObject: typesAsObject,
          typesDetailed: typesDetailed,
        });
      })
      .catch((error) => console.log(error));
  }

  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  handleChange(value, event) {
    this.setState({ value: event });
  }

  handleCheckboxChange = (event) => {
    this.setState({
      typeAsObject: {
        ...this.state.typeAsObject,
        [event.target.name]: event.target.checked,
      },
    });
  };

  handleFileUpload = (file, meta, status) => {
    this.setState({
      file: file,
      abstractUrl: meta.previewUrl,
    });
  };

  handleReset = (values) => {
    this.handleTabChange(null, 0);
    this.setState({
      submitterIsAuthor: false,
      presenterUnknown: false,
      submitterIsPresenter: false,
      file: null,
      abstractUrl: '',
      authorCount: 0,
      // typeAsObject: { talk: true, poster: true }, // MS&T days only
      typeAsObject: { talk: false, poster: false },
    });
  };

  populateSubmitterAsAuthor = (setFieldValue) => {
    this.setState({ submitterIsAuthor: !this.state.submitterIsAuthor });
    if (!this.state.submitterIsAuthor) {
      setFieldValue(
        'authors[0].authorFirstName',
        this.state.existingRegistrant.first_name
      );
      setFieldValue(
        'authors[0].authorLastName',
        this.state.existingRegistrant.last_name
      );
      setFieldValue(
        'authors[0].authorEmail',
        this.state.existingRegistrant.email
      );
      setFieldValue(
        'authors[0].authorCompany',
        this.state.existingRegistrant.account_name
      );
      setFieldValue(
        'authors[0].authorJobTitle',
        this.state.existingRegistrant.title
      );
      setFieldValue(
        'authors[0].authorLinkedInUrl',
        this.state.existingRegistrant.linked_in_url
      );
    } else {
      setFieldValue('authors[0].authorFirstName', '');
      setFieldValue('authors[0].authorLastName', '');
      setFieldValue('authors[0].authorEmail', '');
      setFieldValue('authors[0].authorCompany', '');
      setFieldValue('authors[0].authorJobTitle', '');
      setFieldValue('authors[0].authorLinkedInUrl', '');
    }
  };

  populateSubmitterAsPresenter = (setFieldValue, setFieldTouched) => {
    // this.setState({submitterIsPresenter: !this.state.submitterIsPresenter})
    if (!this.state.submitterIsPresenter) {
      setFieldValue(
        'presenters[0].presenterFirstName',
        this.state.existingRegistrant.first_name
      );
      setFieldValue(
        'presenters[0].presenterLastName',
        this.state.existingRegistrant.last_name
      );
      setFieldValue(
        'presenters[0].presenterEmail',
        this.state.existingRegistrant.email
      );
      setFieldValue(
        'presenters[0].presenterCompany',
        this.state.existingRegistrant.account_name
      );
      setFieldValue(
        'presenters[0].presenterJobTitle',
        this.state.existingRegistrant.title
      );
      setFieldValue(
        'presenters[0].presenterLinkedInUrl',
        this.state.existingRegistrant.linked_in_url
      );
    } else {
      setFieldValue('presenters[0].presenterFirstName', '');
      setFieldValue('presenters[0].presenterLastName', '');
      setFieldValue('presenters[0].presenterEmail', '');
      setFieldValue('presenters[0].presenterCompany', '');
      setFieldValue('presenters[0].presenterJobTitle', '');
      setFieldValue('presenters[0].presenterLinkedInUrl', '');
    }
    setFieldTouched('presenters[0].presenterFirstName', false);
    setFieldTouched('presenters[0].presenterLastName', false);
    setFieldTouched('presenters[0].presenterEmail', false);
    setFieldTouched('presenters[0].presenterCompany', false);
    setFieldTouched('presenters[0].presenterJobTitle', false);
    setFieldTouched('presenters[0].presenterLinkedInUrl', false);
    this.setState({ submitterIsPresenter: !this.state.submitterIsPresenter });
  };

  handlePresenterUnknown = (setFieldValue, setFieldTouched) => {
    if (this.state.submitterIsPresenter && !this.state.presenterUnknown) {
      setFieldValue('presenters[0].presenterFirstName', '');
      setFieldValue('presenters[0].presenterLastName', '');
      setFieldValue('presenters[0].presenterEmail', '');
      setFieldValue('presenters[0].presenterCompany', '');
      setFieldValue('presenters[0].presenterJobTitle', '');
      setFieldValue('presenters[0].presenterLinkedInUrl', '');
    }
    if (this.state.submitterIsPresenter && this.state.presenterUnknown) {
      setFieldValue(
        'presenters[0].presenterFirstName',
        this.state.existingRegistrant.first_name
      );
      setFieldValue(
        'presenters[0].presenterLastName',
        this.state.existingRegistrant.last_name
      );
      setFieldValue(
        'presenters[0].presenterEmail',
        this.state.existingRegistrant.email
      );
      setFieldValue(
        'presenters[0].presenterCompany',
        this.state.existingRegistrant.account_name
      );
      setFieldValue(
        'presenters[0].presenterJobTitle',
        this.state.existingRegistrant.title
      );
      setFieldValue(
        'presenters[0].presenterLinkedInUrl',
        this.state.existingRegistrant.linked_in_url
      );
    }
    this.setState({ presenterUnknown: !this.state.presenterUnknown });
  };

  render() {
    const { classes } = this.props;

    let tabRow;
    if (this.state.tabValue > 3) {
      tabRow = null;
    } else {
      tabRow = (
        <Tabs
          value={this.state.tabValue}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            disableRipple
            className={classes.tabNoCursor}
            label={`1 ${this.props.t('abstractSubmission.email')}`}
          />
          <Tab
            disableRipple
            className={classes.tabNoCursor}
            label={`2 ${this.props.t('abstractSubmission.abstractInfo')}`}
          />
          <Tab
            disableRipple
            className={classes.tabNoCursor}
            label={`3 ${this.props.t('abstractSubmission.authorInfo')}`}
          />
          <Tab
            disableRipple
            className={classes.tabNoCursor}
            label={`4 ${this.props.t('abstractSubmission.presenterInfo')}`}
          />
        </Tabs>
      );
    }

    const InfoField = ({ ...props }) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <Field> and alse replace ErrorMessage entirely.
      const [field, meta] = useField(props);
      if (!field.value) {
        field.value = '';
      }

      return (
        <>
          <Field
            onChange={() => this.handleChange(field.name, field.value)}
            className={classes.infoField}
            component={TextField}
            margin="dense"
            variant="outlined"
            fullWidth
            {...field}
            {...props}
          />
          {meta.touched && meta.error ? (
            <div className={classes.error}>{meta.error}</div>
          ) : null}
        </>
      );
    };

    const SelectField = ({ ...props }) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <Field> and alse replace ErrorMessage entirely.
      const [field, meta] = useField(props);
      if (!field.value) {
        field.value = '';
      }

      let passedOptions;
      if (props.options) {
        passedOptions = props.options.map((item, index) => {
          let newItem;
          switch (item) {
            case `Yes - Approved`:
              newItem = this.props.t('abstractSubmission.yesApproved');
              break;
            case `No - Applying for Approval`:
              newItem = this.props.t('abstractSubmission.noApplyingApproval');
              break;
            case `No`:
              newItem = this.props.t('abstractSubmission.noApproved');
              break;
            case `Other`:
              newItem = this.props.t('abstractSubmission.otherApproved');
              break;
            default:
              newItem = item;
          }

          return (
            <option value={item} key={index}>
              {newItem}
            </option>
          );
        });
      }
      return (
        <>
          <Field
            onChange={() => this.handleChange(field.name, field.value)}
            className={classes.selectField}
            component={NativeSelect}
            margin="none"
            variant="standard"
            fullWidth
            {...field}
            {...props}
          >
            <option aria-label="None" value="">
              {props.placeholder}
            </option>
            {passedOptions}
          </Field>
          {meta.touched && meta.error ? (
            <div className={classes.error}>{meta.error}</div>
          ) : null}
        </>
      );
    };

    const CheckboxField = ({ ...props }) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <Field> and alse replace ErrorMessage entirely.
      const [field, meta] = useField(props);
      if (!field.value) {
        field.value = '';
      }
      let atLeastOneSelected = false;
      for (const property in props.selected) {
        if (props.selected[property]) {
          atLeastOneSelected = true;
        }
      }

      const formOptions = [];
      let typeObject = this.state.typesDetailed;
      for (const property in typeObject) {
        let label;
        switch (typeObject[property].label) {
          case `Talk`:
            label = this.props.t('abstractSubmission.talk');
            break;
          case `Poster`:
            label = this.props.t('abstractSubmission.poster');
            break;
          default:
            label = typeObject[property].label;
            break;
        }
        formOptions.push(
          <FormControlLabel
            key={typeObject[property].field_id}
            control={
              <Checkbox
                checked={this.state.typeAsObject[property]}
                onChange={this.handleCheckboxChange}
                name={typeObject[property].name}
              />
            }
            label={label}
          />
        );
      }

      return (
        <div className={classes.root}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{props.placeholder}</FormLabel>
            {meta.touched & !atLeastOneSelected ? (
              <div className={classes.error}>{this.state.checkboxError}</div>
            ) : null}
            <FormGroup>
              {Object.keys(this.state.typesDetailed).length > 0
                ? formOptions
                : null}
            </FormGroup>
          </FormControl>
        </div>
      );
    };

    let authorObject = () => {
      return {
        authorFirstName: '',
        authorLastName: '',
        authorEmail: '',
        authorCompany: '',
        authorJobTitle: '',
        authorLinkedInUrl: '',
      };
    };

    let authorGroup = [];
    for (let i = 0; i <= this.state.authorCount; i++) {
      authorGroup.push(authorObject());
    }

    let presenterObject = () => {
      return {
        presenterFirstName: '',
        presenterLastName: '',
        presenterEmail: '',
        presenterCompany: '',
        presenterJobTitle: '',
        presenterLinkedInUrl: '',
        presenterBiography: '',
      };
    };

    let presenterGroup = [];
    for (let i = 0; i <= this.state.presenterCount; i++) {
      presenterGroup.push(presenterObject());
    }

    let baseValues = {
      email: this.props.user?.user?.email,
      type: [],
      topic: '',
      title: '',
      abstractContent: '',
      currentTag: '',
      cleared_for_public_view: 'Yes - Approved',
      tags: [],
      abstractFile: '',
      authors: [...authorGroup],
      presenters: [...presenterGroup],
    };

    let checkEmail = async (values) => {
      await axios({
        method: 'GET',
        url: `/show-attendees/${eventId}/${values.email}`,
      }).then((responseRegistrant) => {
        let existingRegistrant;
        if (responseRegistrant.data[0]) {
          existingRegistrant = responseRegistrant.data[0];

          this.setState({
            existingRegistrant: existingRegistrant,
            emailNotRegistered: false,
          });
          this.handleTabChange(null, 1);
        } else {
          existingRegistrant = null;
          this.setState({ emailNotRegistered: true });
          setTimeout(() => {
            this.setState({ emailNotRegistered: false });
          }, 4000);
        }
      });
      if (this.state.existingRegistrant) {
        await axios({
          method: 'get',
          url: 'events/' + this.state.existingRegistrant.show_id,
          // headers: { idtoken: data.idToken.jwtToken },
          params: { eventId: this.state.existingRegistrant.show_id },
        }).then((response) => {
          // set up variables to assign to state
          let config = response.data;
          let topicMap = JSON.parse(config.topic_map);
          const topicMapArray = Object.keys(topicMap);
          topicMapArray.shift(); // remove default
          this.setState({
            topicMap: topicMapArray,
          });
        });
      }
    };

    let formikSubmit = async (values, setSubmitting) => {
      setSubmitting(true);
      // const user = await Auth.currentSession()

      this.handleTabChange(null, 4);

      values.tags = values.tags.join(',');
      let typeAsArray = [];
      for (let type in this.state.typeAsObject) {
        if (this.state.typeAsObject[type]) {
          typeAsArray.push(type);
        }
      }
      values.type = typeAsArray;

      if (this.state.file) {
        let formData = new FormData();
        formData.append('file', this.state.file);

        const uploadFile = await axios({
          method: 'POST',
          url: '/upload',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          data: formData,
        });

        values.abstractFile = uploadFile.data.Location;
      }

      let submitter = {
        role: 'Submitter',
        first_name: this.state?.existingRegistrant?.first_name,
        last_name: this.state?.existingRegistrant?.last_name,
        job_title: this.state?.existingRegistrant?.title,
        email: this.state?.existingRegistrant?.email,
        account_id: accountId,
        bio: null,
      };

      const authorsArray = [];
      values.authors.forEach((author) => {
        let authorObject = {
          role: 'Author',
          first_name: author.authorFirstName,
          last_name: author.authorLastName,
          job_title: author.authorJobTitle,
          email: author.authorEmail,
          linked_in_url: author.authorLinkedInUrl,
          account_id: accountId,
          bio: null,
        };
        authorsArray.push(authorObject);
      });

      let presenter = {
        role: 'Presenter',
        first_name: values.presenters[0].presenterFirstName,
        last_name: values.presenters[0].presenterLastName,
        job_title: values.presenters[0].presenterJobTitle,
        email: values.presenters[0].presenterEmail,
        linked_in_url: values.presenters[0].presenterLinkedInUrl,
        account_id: accountId,
        bio: values.presenters[0].presenterBiography,
      };

      const people = [];

      if (presenter.email) {
        people.push(presenter);
      }
      people.push(submitter);
      if (authorsArray[0].email) {
        authorsArray.forEach((author) => people.push(author));
      }

      const merged = new Map();

      people.forEach((element) => {
        if (merged.get(element.email)) {
          let contact = merged.get(element.email);
          contact.role.push(element.role);
          merged.set(element.email, contact);
        } else {
          element.role = element.role.split();
          merged.set(element.email, element);
        }
      });

      values.abstract_contacts = Array.from(merged.values());

      // if (false) {
      if (this.state.tabValue > 3) {
        // UNCOMMENT OUT WHEN READY TO ACTUALLY POST
        if (this.state.presenterUnknown) {
          values.presenters = [presenterObject()];
        }

        let sequenceNumber;
        let submitError;
        // setTimeout(() => {
        await axios({
          method: 'POST',
          url: `zoho/abstracts/${this.state.existingRegistrant.show_id}/${this.state.existingRegistrant.account_id}`,
          // headers: { idtoken: user.idToken.jwtToken },
          data: {
            values,
            contactId: this.state.existingRegistrant.contact_id,
          },
        })
          .then((response) => {
            sequenceNumber = response.data.data.Name;
          })
          .catch((error) => {
            console.log(error);
            submitError = error;
          });
        // }, 500);

        let subjectSubmitter = `${this.props.event.event.title} ${this.props.t(
          'abstractSubmission.ascSubmitter'
        )}`;
        let subjectAuthorPresenter = `${
          this.props.event.event.title
        } ${this.props.t('abstractSubmission.ascPresenterAuthor')}`;

        let abstractInformation = {};
        abstractInformation.title = values.title;
        abstractInformation.abstractContent = values.abstractContent;
        abstractInformation.topic = values.topic;
        abstractInformation.type = values.type;
        abstractInformation.keywords = values.tags;
        abstractInformation.sequenceNumber = sequenceNumber;
        let bodyAbstract = FormatObject(abstractInformation);

        let submitterInformation = submitter;
        submitterInformation.role = this.props.t(
          'abstractSubmission.submitter'
        );
        delete submitterInformation.account_id;
        delete submitterInformation.bio;

        // change these based on the event
        let body1submitter = `${this.props.t(
          'abstractSubmission.body1submitterA'
        )} ${this.props.event.event.title}. ${this.props.t(
          'abstractSubmission.body1submitterB'
        )}`;
        let body1authorPresenter = `${this.props.t(
          'abstractSubmission.body1authorPresenterA'
        )} ${this.props.event.event.title}.  ${this.props.t(
          'abstractSubmission.body1authorPresenterB'
        )} ${this.props.event.event.landing_page_url}.`;
        let body2authorPresenter = this.props.t(
          'abstractSubmission.body2authorPresenter'
        );

        // handles multiple authors, creates the body of the email and prepares the object for email service
        const authorsEmails = ['registration@planetconnect.com'];
        const emailObjectsAuthorsArray = [];
        authorsArray.forEach((author) => {
          // data present in bodyAbstract
          // authorInformation.sequenceNumber = sequenceNumber;
          // authorInformation.type = values.type;
          // authorInformation.topic = values.topic;
          // authorInformation.abstractContent = values.abstractContent;
          // authorInformation.keywords = values.tags;
          // end data
          let authorInformation = author;
          authorInformation.role = this.props.t('abstractSubmission.author');
          delete authorInformation.account_id; // info not needed in email
          delete authorInformation.bio; // info not needed in email
          authorsEmails.push(author.email);
          let body2Author = FormatObject(authorInformation);

          let bodyAuthor = `${body1authorPresenter}<br/>${body2authorPresenter}<br/>${bodyAbstract}<br/>${body2Author}`; // if more body sections are needed, add them above and then format here
          let emailObjectAuthor = {
            to: [
              `${authorInformation.first_name} ${authorInformation.last_name} <${authorInformation.email}>`,
            ],
            subject: subjectAuthorPresenter,
            body: bodyAuthor,
            title: '',
            // title: values.title,
            banner: this.props.event.event.banner_location,
          };

          let bodyOfEmailAuthor = EmailTemplate(emailObjectAuthor);
          emailObjectAuthor.body = bodyOfEmailAuthor;

          emailObjectsAuthorsArray.push(emailObjectAuthor);
        });

        let presenterIsAnAuthor = authorsEmails.includes(presenter.email);
        // data present in bodyAbstract
        // presenterInformation.sequenceNumber = sequenceNumber;
        // presenterInformation.type = values.type;
        // presenterInformation.topic = values.topic;
        // presenterInformation.abstractContent = values.abstractContent;
        // presenterInformation.keywords = values.tags;
        // end data
        let presenterInformation = presenter;
        presenterInformation.role = presenterIsAnAuthor
          ? `${this.props.t('abstractSubmission.author')}, ${this.props.t(
              'abstractSubmission.presenter'
            )}`
          : this.props.t('abstractSubmission.presenter');
        delete presenterInformation.account_id;

        let body2Submitter = FormatObject(submitterInformation);
        let body2Presenter = FormatObject(presenterInformation);

        let bodySubmitter = `${body1submitter}<br/>${bodyAbstract}<br/>${body2Submitter}<br/>`; // if more body sections are needed, add them above and then format here
        let bodyPresenter = `${body1authorPresenter}<br/>${body2authorPresenter}<br/>${bodyAbstract}<br/>${body2Presenter}`; // if more body sections are needed, add them above and then format here

        let emailObjectSubmitter = {
          to: [
            `${submitterInformation.first_name} ${submitterInformation.last_name} <${submitterInformation.email}>`,
            'registration@planetconnect.com',
          ],
          subject: subjectSubmitter,
          body: bodySubmitter,
          title: '',
          // title: values.title,
          banner: this.props.event.event.banner_location,
        };

        let emailObjectPresenter = {
          to: [
            `${presenterInformation.first_name} ${presenterInformation.last_name} <${presenterInformation.email}>`,
            'registration@planetconnect.com',
          ],
          subject: subjectAuthorPresenter,
          body: bodyPresenter,
          title: '',
          // title: values.title,
          banner: this.props.event.event.banner_location,
        };

        let bodyOfEmailSubmitter = EmailTemplate(emailObjectSubmitter);
        let bodyOfEmailPresenter = EmailTemplate(emailObjectPresenter);

        emailObjectSubmitter.body = bodyOfEmailSubmitter;
        emailObjectPresenter.body = bodyOfEmailPresenter;

        let errorIntro =
          'The following abstract submission encountered an error.';
        let subjectError = `ERROR: ${this.props.event.event.title} Abstract Submission Error`;
        let bodyError = `${errorIntro}<br/>${body2Submitter}<br/>${submitError}`; // if more body sections are needed, add them above and then format here

        let emailObjectError = {
          to: ['registration@planetconnect.com', 'rjanelli@planetconnect.com'],
          subject: subjectError,
          body: bodyError,
          title: values.title,
        };

        if (!sequenceNumber || submitError) {
          await axios({
            method: 'POST',
            url: `/mail`,
            data: emailObjectError,
          });
          alert(
            `There was an issue submitting your abstract at this time. Please try again later or reach out to support with this error. ERROR: ${submitError}`
          );
          return;
        } else {
          await axios({
            method: 'POST',
            url: `/mail`,
            data: emailObjectSubmitter,
          });

          if (presenterIsAnAuthor && emailObjectsAuthorsArray.length === 1) {
            await axios({
              method: 'POST',
              url: `/mail`,
              data: emailObjectPresenter,
            });
          } else {
            if (emailObjectsAuthorsArray.length) {
              emailObjectsAuthorsArray.forEach((emailObjectAuthor) => {
                axios({
                  method: 'POST',
                  url: `/mail`,
                  data: emailObjectAuthor,
                });
              });
            }
            if (presenter.email) {
              await axios({
                method: 'POST',
                url: `/mail`,
                data: emailObjectPresenter,
              });
            }
          }
        }
      }
      setSubmitting(false);
    };

    function onKeyDown(keyEvent) {
      // removed so abstract content can use the return key
      // used to prevent submit...
      // if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      //   keyEvent.preventDefault();
      // }
    }

    let validateEmail = (value) => {
      let error;
      if (!value) {
        error = this.props.t('abstractSubmission.required');
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = this.props.t('abstractSubmission.invalidEmail');
      }
      return error;
    };

    let validateEmailOptional = (value) => {
      let error;
      if (!value) {
        error = null;
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = 'Invalid email address';
      }
      return error;
    };

    let validateRequired = (value) => {
      let error;
      if (!value) {
        error = this.props.t('abstractSubmission.required');
      }
      return error;
    };

    let validateMinimumChoices = (minimumNumber, selectedOptions) => {
      let error;
      if (selectedOptions.length < minimumNumber) {
        error = this.props.t('abstractSubmission.minimumRequired');
      }
      return error;
    };

    let authorField = (key, validateEmailOptional) => (
      <Grid item container xs={12} key={key} justifyContent="center">
        <Grid item xs={10}>
          {/* <Divider className={classes.divider}/> */}
          <Typography variant="subtitle1">
            {this.props.t('abstractSubmission.author')} ({key + 1})
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
        {key > 0 ? (
          <Grid item xs={2}>
            <Button
              style={{ backgroundColor: 'red', width: '80%', margin: '0% 20%' }}
              onClick={() => {
                // console.log('AUTHOR REMOVED')
                this.setState({ authorCount: this.state.authorCount - 1 });
              }}
            >
              X
            </Button>
          </Grid>
        ) : null}
        <Grid item xs={6} style={{ paddingRight: '8px' }}>
          {this.state.abstractFields[1].filter(
            (field) => field.name === 'author_first_name'
          )[0] ? (
            <InfoField
              name={'authors[' + key + '].authorFirstName'}
              id={'authors[' + key + '].authorFirstName'}
              label={this.props.t('abstractSubmission.firstName')}
              type="text"
              placeholder={this.props.t('abstractSubmission.firstName')}
              required={
                this.state.abstractFields[1].filter(
                  (field) => field.name === 'author_first_name'
                )[0].is_required
              }
            />
          ) : null}
        </Grid>
        <Grid item xs={6} style={{ paddingLeft: '8px' }}>
          {this.state.abstractFields[1].filter(
            (field) => field.name === 'author_last_name'
          )[0] ? (
            <InfoField
              name={'authors[' + key + '].authorLastName'}
              id={'authors[' + key + '].authorLastName'}
              label={this.props.t('abstractSubmission.lastName')}
              type="text"
              placeholder={this.props.t('abstractSubmission.lastName')}
              required={
                this.state.abstractFields[1].filter(
                  (field) => field.name === 'author_last_name'
                )[0].is_required
              }
            />
          ) : null}
        </Grid>
        {this.state.abstractFields[1].filter(
          (field) => field.name === 'author_email'
        )[0] ? (
          <InfoField
            name={'authors[' + key + '].authorEmail'}
            id={'authors[' + key + '].authorEmail'}
            label={this.props.t('abstractSubmission.email')}
            type="text"
            placeholder={this.props.t('abstractSubmission.authorsEmail')}
            validate={validateEmailOptional}
            required={
              this.state.abstractFields[1].filter(
                (field) => field.name === 'author_email'
              )[0].is_required
            }
          />
        ) : null}
        {this.state.abstractFields[1].filter(
          (field) => field.name === 'author_company'
        )[0] ? (
          <InfoField
            name={'authors[' + key + '].authorCompany'}
            id={'authors[' + key + '].authorCompany'}
            label={this.props.t('abstractSubmission.company')}
            type="text"
            placeholder={this.props.t('abstractSubmission.authorsCompany')}
            required={
              this.state.abstractFields[1].filter(
                (field) => field.name === 'author_company'
              )[0]?.is_required
            }
          />
        ) : null}
        {this.state.abstractFields[1].filter(
          (field) => field.name === 'author_job_title'
        )[0] ? (
          <InfoField
            name={'authors[' + key + '].authorJobTitle'}
            id={'authors[' + key + '].authorJobTitle'}
            label={this.props.t('abstractSubmission.title')}
            type="text"
            placeholder={this.props.t('abstractSubmission.authorsJobTitle')}
            required={
              this.state.abstractFields[1].filter(
                (field) => field.name === 'author_job_title'
              )[0].is_required
            }
          />
        ) : null}
        {this.state.abstractFields[1].filter(
          (field) => field.name === 'author_linkedin_url'
        )[0] ? (
          <InfoField
            name={'authors[' + key + '].authorLinkedInUrl'}
            id={'authors[' + key + '].authorLinkedInUrl'}
            label={this.props.t('abstractSubmission.linkedInUrl')}
            type="text"
            placeholder={this.props.t('abstractSubmission.authorsLinkedInUrl')}
            required={
              this.state.abstractFields[1].filter(
                (field) => field.name === 'author_linkedin_url'
              )[0].is_required
            }
          />
        ) : null}
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    );

    let presenterField = (key) => (
      <Grid item container xs={12} key={key}>
        {this.state.abstractFields[2].filter(
          (field) => field.name === 'presenter_first_name'
        ).length > 0 ? (
          <Grid item xs={6} style={{ paddingRight: '8px' }}>
            <InfoField
              name={'presenters[' + key + '].presenterFirstName'}
              id={'presenters[' + key + '].presenterFirstName'}
              label={this.props.t('abstractSubmission.firstName')}
              type="text"
              placeholder={this.props.t('abstractSubmission.firstName')}
              validate={validateRequired}
              required={
                this.state.abstractFields[2].filter(
                  (field) => field.name === 'presenter_first_name'
                )[0].is_required
              }
            />
          </Grid>
        ) : null}
        {this.state.abstractFields[2].filter(
          (field) => field.name === 'presenter_last_name'
        ).length > 0 ? (
          <Grid item xs={6} style={{ paddingLeft: '8px' }}>
            <InfoField
              name={'presenters[' + key + '].presenterLastName'}
              id={'presenters[' + key + '].presenterLastName'}
              label={this.props.t('abstractSubmission.lastName')}
              type="text"
              placeholder={this.props.t('abstractSubmission.lastName')}
              validate={validateRequired}
              required={
                this.state.abstractFields[2].filter(
                  (field) => field.name === 'presenter_last_name'
                )[0].is_required
              }
            />
          </Grid>
        ) : null}
        {this.state.abstractFields[2].filter(
          (field) => field.name === 'presenter_email'
        ).length > 0 ? (
          <InfoField
            name={'presenters[' + key + '].presenterEmail'}
            id={'presenters[' + key + '].presenterEmail'}
            label={this.props.t('abstractSubmission.email')}
            type="text"
            placeholder={this.props.t('abstractSubmission.presentersEmail')}
            validate={validateEmail}
            required={
              this.state.abstractFields[2].filter(
                (field) => field.name === 'presenter_email'
              )[0].is_required
            }
          />
        ) : null}
        {this.state.abstractFields[2].filter(
          (field) => field.name === 'presenter_company'
        ).length > 0 ? (
          <InfoField
            name={'presenters[' + key + '].presenterCompany'}
            id={'presenters[' + key + '].presenterCompany'}
            label={this.props.t('abstractSubmission.company')}
            type="text"
            placeholder={this.props.t('abstractSubmission.presentersCompany')}
            validate={
              this.state.abstractFields[2].filter(
                (field) => field.name === 'presenter_company'
              )[0].is_required
                ? validateRequired
                : null
            }
            required={
              this.state.abstractFields[2].filter(
                (field) => field.name === 'presenter_company'
              )[0].is_required
            }
          />
        ) : null}
        {this.state.abstractFields[2].filter(
          (field) => field.name === 'presenter_job_title'
        ).length > 0 ? (
          <InfoField
            name={'presenters[' + key + '].presenterJobTitle'}
            id={'presenters[' + key + '].presenterJobTitle'}
            label={this.props.t('abstractSubmission.title')}
            type="text"
            placeholder={this.props.t('abstractSubmission.presentersJobTitle')}
            validate={
              this.state.abstractFields[2].filter(
                (field) => field.name === 'presenter_job_title'
              )[0].is_required
                ? validateRequired
                : null
            }
            required={
              this.state.abstractFields[2].filter(
                (field) => field.name === 'presenter_job_title'
              )[0].is_required
            }
          />
        ) : null}
        {this.state.abstractFields[2].filter(
          (field) => field.name === 'presenter_linkedin_url'
        ).length > 0 ? (
          <InfoField
            name={'presenters[' + key + '].presenterLinkedInUrl'}
            id={'presenters[' + key + '].presenterLinkedInUrl'}
            label={this.props.t('abstractSubmission.linkedInUrl')}
            type="text"
            placeholder={this.props.t(
              'abstractSubmission.presentersLinkedInUrl'
            )}
            validate={
              this.state.abstractFields[2].filter(
                (field) => field.name === 'presenter_linkedin_url'
              )[0].is_required
                ? validateRequired
                : null
            }
            required={
              this.state.abstractFields[2].filter(
                (field) => field.name === 'presenter_linkedin_url'
              )[0].is_required
            }
          />
        ) : null}
        {this.state.abstractFields[2].filter(
          (field) => field.name === 'presenter_bio'
        ).length > 0 ? (
          <>
            <InfoField
              name={'presenters[' + key + '].presenterBiography'}
              id={'presenters[' + key + '].presenterBiography'}
              label={this.props.t('abstractSubmission.biography')}
              type="text"
              placeholder={this.props.t(
                'abstractSubmission.presentersBiography'
              )}
              minRows={5}
              maxRows={Infinity}
              multiline
              required={
                this.state.abstractFields[2].filter(
                  (field) => field.name === 'presenter_bio'
                )[0].is_required
              }
            />
            <Typography align="left" variant="body2">
              {this.props.t('abstractSubmission.bioHelper')}
            </Typography>
          </>
        ) : null}
        {/* <Typography align="left" variant="h6">
          Presenter Photo
        </Typography>
        <Grid item container xs={12} justifyContent="flex-start" align="center">
          <Grid item xs={3}>
            <PersonIcon
              className={classes.img}
              style={{
                width: '80%',
                height: '100%',
                background: '#CCCCCC',
                marginRight: '20%',
              }}
            />
          </Grid>
          <Grid item xs={9} style={{ width: '100%', height: '100%' }}>
            <FileUploader handleFileUpload={this.handleFileUpload.bind(this)} />
          </Grid>
        </Grid> */}
        {/* the above is removed for MVP and pending to be used for VERSION 2 */}
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>
    );

    let allAuthors = (validateEmailOptional) => {
      let allAuthors = [];
      for (let i = 0; i <= this.state.authorCount; i++) {
        let key = i;
        allAuthors.push(authorField(key, validateEmailOptional));
      }
      return allAuthors;
    };

    let allPresenters = (validateEmail, validateRequired) => {
      let allPresenters = [];
      for (let i = 0; i <= this.state.presenterCount; i++) {
        let key = i;
        allPresenters.push(
          presenterField(key, validateEmail, validateRequired)
        );
      }
      return allPresenters;
    };

    let keywordMinimum = 3;

    let bodyContent = (
      isSubmitting,
      values,
      handleChange,
      setFieldValue,
      setFieldTouched,
      handleSubmit
    ) => {
      switch (this.state.tabValue) {
        case 0:
          return (
            <Grid className={classes.gridContainer} container>
              <Grid item container xs={12} justifyContent="center">
                <Grid item xs={6}>
                  <Divider className={classes.divider} />
                  <Typography variant="h6">
                    {this.props.t('abstractSubmission.email')}
                  </Typography>
                  <Divider className={classes.divider} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <InfoField
                  name="email"
                  id="email"
                  label={this.props.t('abstractSubmission.email')}
                  type="text"
                  placeholder={this.props.t('abstractSubmission.yourEmail')}
                  validate={validateEmail}
                  required
                  autoFocus
                />
                {this.state.emailNotRegistered ? (
                  <Typography style={{ color: 'red' }}>
                    {this.props.t('abstractSubmission.emailNotRegistered')}
                  </Typography>
                ) : (
                  <Typography style={{ color: 'white' }}>-</Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={10} sm={12}>
                <Button
                  type="button"
                  className={classes.navButton}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    if (!validateEmail(values.email)) {
                      checkEmail(values);
                    }
                  }}
                >
                  <Typography variant="h6">
                    {this.props.t('abstractSubmission.next')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          );
        case 1:
          return (
            <Grid className={classes.gridContainer} container>
              <Grid item container xs={12} justifyContent="center">
                <Grid item xs={6}>
                  <Divider className={classes.divider} />
                  <Typography variant="h6">
                    {this.props.t('abstractSubmission.abstractInformation')}
                  </Typography>
                  <Divider className={classes.divider} />
                </Grid>
              </Grid>
              <Grid item container xs={12} md={6}>
                {/* <SelectField
                                        name='type'
                                        id='type'
                                        label='Type'
                                        type='text'
                                        placeholder='Abstract Type'
                                        options={['Talk', 'Poster']}
                                        // value={[]}
                                        validate={validateRequired} required
                                    /> */}
                <CheckboxField
                  name="type"
                  id="type"
                  label="Type"
                  type="text"
                  placeholder={`${this.props.t(
                    'abstractSubmission.selectDesiredType'
                  )} *`}
                  validate={validateRequired}
                  selected={this.state.typeAsObject}
                  required
                />
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Divider className={classes.divider} />
                {/* used to push Topic to next line. <br/> didn't work*/}
                <Typography variant="subtitle2">
                  {this.props.t('abstractSubmission.topic')} *
                </Typography>
                <SelectField
                  name="topic"
                  id="topic"
                  label="Topic *"
                  type="text"
                  placeholder={this.props.t('abstractSubmission.abstractTopic')}
                  variant="filled"
                  style={{
                    backgroundColor: '#dbdbdb',
                    borderRadius: '4px 4px 0px 0px',
                    paddingLeft: '8px',
                  }}
                  options={this.state.topicMap}
                  validate={validateRequired}
                  required
                />
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Divider className={classes.divider} />
                <Typography variant="subtitle2">
                  {this.props.t('abstractSubmission.title')} *
                </Typography>
                <InfoField
                  name="title"
                  id="title"
                  label={this.props.t('abstractSubmission.abstractTitle')}
                  type="text"
                  placeholder={this.props.t(
                    'abstractSubmission.yourAbstractTitle'
                  )}
                  validate={validateRequired}
                  required
                />
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Divider className={classes.divider} />
                <Typography variant="subtitle2">
                  {this.props.t('abstractSubmission.abstractContent')} *
                </Typography>
                <Grid item xs={12} />
                <Typography align="left" variant="body2">
                  {this.props.t('abstractSubmission.abstractContentHelper')}
                </Typography>
                <InfoField
                  name="abstractContent"
                  id="abstractContent"
                  label={this.props.t('abstractSubmission.content')}
                  type="text"
                  placeholder={this.props.t(
                    'abstractSubmission.yourAbstractContent'
                  )}
                  minRows={5}
                  maxRows={Infinity}
                  multiline
                  validate={validateRequired}
                  required
                />
                <div style={{display: "none"}}>
                  <Typography variant="subtitle2">
                    {this.props.t('abstractSubmission.clearedForPublicView')} *
                  </Typography>
                  <SelectField
                    name="cleared_for_public_view"
                    id="cleared_for_public_view"
                    label={`${this.props.t(
                      'abstractSubmission.clearedForPublicView'
                    )} *`}
                    type="text"
                    placeholder={this.props.t(
                      'abstractSubmission.clearedForPublicView'
                    )}
                    variant="filled"
                    style={{
                      backgroundColor: '#dbdbdb',
                      borderRadius: '4px 4px 0px 0px',
                      paddingLeft: '8px',
                    }}
                    options={this.state.publicViewOptions}
                    selected="No"
                  />
                </div>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography align="center" variant="body2">
                      {this.props.t('abstractSubmission.keywordTags')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FieldArray
                      name="tagFields"
                      render={() => {
                        return (
                          <>
                            <KeywordLookupField
                              name="tags"
                              id="tags"
                              label={this.props.t(
                                'abstractSubmission.keywords'
                              )}
                              type="text"
                              placeholder={this.props.t(
                                'abstractSubmission.yourAbstractTags'
                              )}
                              value=""
                              onSelect={setFieldValue}
                              validate={validateMinimumChoices(
                                keywordMinimum,
                                values.tags
                              )}
                              required
                            />
                            {/* <CompanyTagField
                              name="currentTag"
                              id="currentTag"
                              label="Input Your Abstract Tags"
                              values={values}
                              onFocus={this.tagFieldFocus}
                              onBlur={this.tagFieldBlur}
                            />
                            <CompanyChips
                              name="tags"
                              id="tags"
                              label="Tags"
                              values={values}
                            /> */}
                          </>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                  <Typography
                    className={classes.header}
                    align="center"
                    style={{ textAlign: 'center' }}
                    variant="h6"
                  >
                    Upload Supporting Image
                  </Typography>
                  {this.state.abstractUrl ? (
                    // <div style={{display: 'block', height: 0, paddingBottom: "56.25%", position: 'relative', marginLeft: '25%', marginRight: '25%'}}>
                    <Img
                      src={this.state.abstractUrl}
                      alt="abstract document"
                      width="50%"
                      // style={{position: 'absolute', maxHeight: '100%',}}
                    />
                  ) : (
                    // </div>
                    <React.Fragment></React.Fragment>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FileUploader
                    // handleFileUpload = {this.handleFileUpload.bind(this, setFieldValue)}
                    handleFileUpload={this.handleFileUpload.bind(this)}
                  />
                </Grid> */}
              </Grid>
              {/* the above is removed for MVP and pending to be used for VERSION 2 */}
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={10} sm={12}>
                <Button
                  type="button"
                  className={classes.navButton}
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={this.state.tabValue !== 1}
                  onClick={() => {
                    this.handleTabChange(null, 0);
                  }}
                >
                  <Typography variant="h6">
                    {this.props.t('abstractSubmission.previous')}
                  </Typography>
                </Button>
                <Button
                  type="button"
                  className={classes.navButton}
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={this.state.tabValue !== 1}
                  onClick={() => {
                    // Check and validate all fields before proceeding
                    let fields = [
                      'topic',
                      'type',
                      'abstractContent',
                      'title',
                      'tags',
                    ];
                    fields.forEach((element) => {
                      setFieldTouched(element, true);
                    });
                    let checkboxValid = false;
                    for (const value in this.state.typeAsObject) {
                      if (this.state.typeAsObject[value] === true) {
                        checkboxValid = true;
                      }
                    }
                    if (!checkboxValid) {
                      this.setState({ checkboxError: 'Required' });
                    } else {
                      this.setState({ checkboxError: null });
                    }
                    if (
                      values.topic !== '' &&
                      checkboxValid &&
                      values.abstractContent !== '' &&
                      values.title !== '' &&
                      values.tags.length >= keywordMinimum
                    ) {
                      this.handleTabChange(null, 2);
                    }
                  }}
                >
                  <Typography variant="h6">
                    {this.props.t('abstractSubmission.next')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          );
        case 2:
          return (
            <Grid className={classes.gridContainer} container>
              <Grid item container xs={12} justifyContent="center">
                <Grid item xs={6}>
                  <Divider className={classes.divider} />
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="flex-start"
                    align="center"
                    style={{ marginTop: '16px', marginBottom: '16px' }}
                  >
                    <Grid item className={classes.toggleButton}>
                      <ToggleButton
                        value="check"
                        selected={this.state.submitterIsAuthor}
                        onChange={() =>
                          this.populateSubmitterAsAuthor(setFieldValue)
                        }
                      >
                        <CheckIcon />
                      </ToggleButton>
                    </Grid>
                    <Grid item className={classes.toggleText}>
                      <Typography align="left" variant="body1">
                        <b>
                          {this.props.t('abstractSubmission.areYouTheAuthor')}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <Typography variant="h6">
                    {this.props.t('abstractSubmission.authorInformation')}
                  </Typography>
                  <Typography variant="subtitle1">
                    {this.props.t('abstractSubmission.optional')}
                  </Typography>
                  <Divider className={classes.divider} />
                </Grid>
              </Grid>
              <Grid item container xs={12} justifyContent="center">
                <Grid item xs={6}>
                  {allAuthors(validateEmailOptional)}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="text"
                  onClick={() => {
                    this.setState({ authorCount: this.state.authorCount + 1 });
                  }}
                >
                  <Typography align="left" variant="h6">
                    {this.props.t('abstractSubmission.addAuthor')}
                  </Typography>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={10} sm={12}>
                <Button
                  type="button"
                  className={classes.navButton}
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={this.state.tabValue !== 2}
                  onClick={() => {
                    this.handleTabChange(null, 1);
                  }}
                >
                  <Typography variant="h6">
                    {this.props.t('abstractSubmission.previous')}
                  </Typography>
                </Button>
                <Button
                  type="button"
                  className={classes.navButton}
                  color="primary"
                  variant="contained"
                  size="large"
                  disabled={this.state.tabValue !== 2}
                  onClick={() => {
                    // Check and validate all fields before proceeding
                    let fields = [
                      'author[0].authorFirstName',
                      'author[0].authorLastName',
                      'author[0].authorEmail',
                      'author[0].authorCompany',
                      'author[0].authorJobTitle',
                      'author[0].authorLinkedInUrl',
                    ];
                    fields.forEach((element) => {
                      setFieldTouched(element, true);
                    });
                    // if (values.topic !== '' && values.type !== '' && values.abstractContent !== '' && values.title !== '') {
                    this.handleTabChange(null, 3);
                    // }
                  }}
                >
                  <Typography variant="h6">
                    {this.props.t('abstractSubmission.next')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          );
        case 3:
          return (
            <Grid className={classes.gridContainer} container>
              <Grid item container xs={12} justifyContent="center">
                <Grid item xs={6}>
                  <Divider className={classes.divider} />
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="flex-start"
                    align="center"
                    style={{ marginTop: '16px', marginBottom: '16px' }}
                  >
                    <Grid item className={classes.toggleButton}>
                      <ToggleButton
                        value="check"
                        selected={this.state.presenterUnknown}
                        onChange={() =>
                          this.handlePresenterUnknown(
                            setFieldValue,
                            setFieldTouched
                          )
                        }
                      >
                        <CheckIcon />
                      </ToggleButton>
                    </Grid>
                    <Grid item className={classes.toggleText}>
                      <Typography align="left" variant="body1">
                        <b>
                          {this.props.t('abstractSubmission.presenterUnknown')}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                  <Grid
                    item
                    container
                    xs={12}
                    justifyContent="flex-start"
                    align="center"
                    style={{ marginTop: '16px', marginBottom: '16px' }}
                  >
                    <Grid item className={classes.toggleButton}>
                      <ToggleButton
                        disabled={this.state.presenterUnknown ? true : false}
                        value="check"
                        selected={this.state.submitterIsPresenter}
                        onChange={() =>
                          this.populateSubmitterAsPresenter(
                            setFieldValue,
                            setFieldTouched
                          )
                        }
                      >
                        <CheckIcon />
                      </ToggleButton>
                    </Grid>
                    <Grid item className={classes.toggleText}>
                      <Typography align="left" variant="body1">
                        <b>
                          {this.props.t(
                            'abstractSubmission.areYouThePresenter'
                          )}
                        </b>
                      </Typography>
                    </Grid>
                  </Grid>
                  {this.state.presenterUnknown ? null : (
                    <>
                      <Divider className={classes.divider} />
                      <Typography variant="h6">
                        {this.props.t(
                          'abstractSubmission.presenterInformation'
                        )}
                      </Typography>
                      <Divider className={classes.divider} />
                    </>
                  )}
                </Grid>
              </Grid>
              {this.state.presenterUnknown ? null : (
                <Grid item container xs={12} justifyContent="center">
                  <Grid item xs={6}>
                    {/* {allPresenters} */}
                    {allPresenters(validateEmail, validateRequired)}
                  </Grid>
                </Grid>
              )}
              {/* {this.state.presenterUnknown ? null : 
                                    <Grid item xs={12}>
                                            <Button
                                                // size='large'
                                                variant='text'
                                                onClick={()=>{
                                                    console.log('ADD PRESENTER CLICKED')
                                                    console.log(this.state.presenterCount);
                                                    this.setState({presenterCount: this.state.presenterCount+1});
                                                    console.log(this.state.presenterCount);
                                                }}
                                            >
                                                <Typography align='left' variant='h6' >
                                                    + Add Additional Presenter
                                                </Typography>
                                            </Button>
                                    </Grid>
                                } */}
              {/* to be added back in VERSION 2 */}
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={10} sm={12}>
                <Button
                  type="button"
                  className={classes.navButton}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    this.handleTabChange(null, 2);
                  }}
                >
                  <Typography variant="h6">
                    {this.props.t('abstractSubmission.previous')}
                  </Typography>
                </Button>
                <Button
                  type="button"
                  className={classes.submitButton}
                  // className={classes.navButton}
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                >
                  <Typography variant="h6">
                    {this.props.t('abstractSubmission.submit')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          );
        case 4:
          return (
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'center', padding: '60px' }}
              >
                <Typography
                  variant="h4"
                  justifyContent="center"
                  align="center"
                  style={{ marginBottom: '60px' }}
                >
                  {this.props.t('abstractSubmission.thankYou')}
                </Typography>
                <Button
                  type="text"
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={() => this.handleReset(values)}
                  style={{ fontSize: '1.3rem', padding: '16px' }}
                >
                  {this.props.t('abstractSubmission.submitAnother')}
                </Button>
              </Grid>
            </Grid>
          );
        default:
          return null;
      }
    };

    let mainSection = () => {
      return (
        <Formik
          initialValues={baseValues}
          onSubmit={(values, { setSubmitting, resetForm }, setFieldTouched) => {
            formikSubmit(values, setSubmitting);
            resetForm(baseValues);
          }}
        >
          {({
            isSubmitting,
            values,
            handleChange,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
          }) => (
            <Form onKeyDown={onKeyDown}>
              {bodyContent(
                isSubmitting,
                values,
                handleChange,
                setFieldValue,
                setFieldTouched,
                handleSubmit
              )}
            </Form>
          )}
        </Formik>
      );
    };
    return (
      <div className={classes.root}>
        <NavBar />
        <Grid
          container
          layout={'row'}
          spacing={0}
          style={{ marginTop: '80px' }}
          justifyContent="center"
        >
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              {this.props.t('abstractSubmission.abstractSubmission')}
            </Typography>
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            {this.state.tabValue <= 3 ? tabRow : null}
          </Grid>
          <Grid item xs={12}>
            {mainSection()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    event: state.event,
  };
}

export default connect(mapStateToProps)(
  withStyles(useStyles)(withTranslation()(AbstractSubmission))
);
