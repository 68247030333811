import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          navbar: {
            home: `Home`,
            virtualEnvironment: `Virtual \n Environment`,
            abstractSubmission: `Abstract \n Submission`,
            agenda: `Agenda`,
            attendeeList: `Attendee \n List`,
            exhibitorList: `Exhibitor \n List`,
            floorplan: `Floorplan`,
            venue: `Venue`,
          },
          landing: {
            signIn: `Sign In`,
            enterVE: `Enter`,
            abstractSubmission: `Abstract Submission`,
            agenda: `Agenda`,
            attendeeList: `Attendee List`,
            exhibitorList: `Exhibitor List`,
            floorplan: `Floorplan`,
            venue: `Venue`,
            attendee: `Attendee`,
            exhibitor: `Exhibitor`,
            attendeeInterests: `Attendee Interests`,
            inPerson: `In Person`,
            virtualOnly: `Virtual Only`,
            total: `Total`,
            exhTopInterest: `Exhibitors and Topics of Interest`,
            exhInterest: `Exhibitors of Interest`,
            topAttInterest: `Topics and number of attendees interested`,
            formatHelper: `Format = Topic name: Total interest count (On-site only interest count)`,
            eventHorizonAbout: `The EventHorizon Virtual Environment component will open approximately one week before the event begins. This environment will provide access to talks, networking and other assets.`,
          },
          tableHeaders: {
            accountName: `Account Name`,
            sponsorshipLevel: `Sponsorship Level`,
            boothNumber: `Booth Number`,
            website: `Website`,
            tags: `Tags`,
            lastName: `Last Name`,
            firstName: `First Name`,
            site: `Site`,
            department: `Department`,
            division: `Division`,
            attendance: `Attendance`,
            email: `Email`,
            company: `Company`,
            roles: `Role(s)`,
          },
          registration: {
            welcomeToThe: `Welcome to the`,
            enterEmail: `Let's get started! Please provide your email address below and click next. \n We'll determine if you've registered already.`,
            next: `Next`,
            enter: `Enter`,
            password: `Password`,
            resetPassword: `Reset Password`,
            inputPassword: `Please input your password below to sign in.`,
            forgottenPassword: `If you have forgotten your password, you may reset it below.`,
            reset: `Reset`,
            passwordWasReset: `Your password has been reset`,
            checkEmailConfirmation: `Check your email for a confirmation code and enter it below`,
            confirmNewPassword: `Confirm New Password`,
            confirmationCode: `Confirmation Code`,
            newPassword: `New Password`,
            alrightSetUp: `Alright! Let's get you set up.`,
            pleaseFillFields: `Please fill out all fields below and confirm you have reviewed the Privacy Policy, then click Register.`,
            email: 'Email',
            lastName: `Last Name`,
            firstName: `First Name`,
            jobTitle: `Job Title`,
            company: `Company`,
            department: `Department`,
            division: `Division`,
            site: `Site`,
            addressStreet: `Street Address`,
            addressCity: `City`,
            addressState: `State`,
            addressZip: `Zip`,
            addressCountry: `Country`,
            phoneNumber: `Phone  Number`,
            privacyPolicy: `Privacy \n Policy`,
            viewPrivacyPolicy: `View \n Privacy \n Policy`,
            readPrivacyPolicy: `I have read and understand the Privacy Policy`,
            register: `Register`,
            topicsOfInterest: `Which topics are you interested in?`,
            companiesOfInterest: `Suppliers/Exhibitors of interest`,
            companiesOfInterestTooltip: `Start typing to see list. If exhibitor is not in the list, type it out and hit enter to submit.`,
            attendanceQuestion: `How will you be attending?`,
            attendanceAnswers: {
              virtual: `Virtual`,
              day1: `Day 1`,
              day2: `Day 2`,
            },
          },
          abstractSubmission: {
            abstractSubmission: `Abstract Submission`,
            abstractInfo: `Abstract Info`,
            abstractInformation: `Abstract Information`,
            authorInfo: `Author Info`,
            authorInformation: `Author Information`,
            presenterInfo: `Presenter Info`,
            presenterInformation: `Presenter Information`,
            // general
            submitter: `Submitter`,
            previous: `Previous`,
            next: `Next`,
            submit: `Submit`,
            email: `Email`,
            optional: `Optional`,
            firstName: `First Name`,
            lastName: `Last Name`,
            company: `Company`,
            title: `Title`,
            linkedInUrl: `LinkedIn URL`,
            biography: `Biography`,
            yes: `Yes`,
            no: `No`,
            // email
            yourEmail: `Your Email`,
            // abstract info
            selectDesiredType: `Select Desired Abstract Type (Select all that apply)`,
            talk: `Talk`,
            poster: `Poster`,
            topic: `Topic`,
            abstractTopic: `Abstract Topic`,
            selectAbstractTopic: `Select Abstract Topic`,
            abstractTitle: `Title`,
            yourAbstractTitle: `Your Abstract Title`,
            abstractContent: `Abstract Content`,
            abstractContentHelper: `The maximum word count is 400 words. Content beyond that may be cut-off. Enter content with no markup.`,
            content: `Content`,
            yourAbstractContent: `Your Abstract Content`,
            clearedForPublicView: `Cleared for Public View - Viewable to External Companies`,
            yesApproved: `Yes - Approved`,
            noApplyingApproval: `No - Applying for Approval`,
            noApproved: `No`,
            otherApproved: `Other`,
            keywordTags: `Keyword Tags (Press Enter to add tags)`,
            keywords: `Keywords`,
            yourAbstractTags: `Your Abstract tags`,
            // author info
            author: `Author`,
            areYouTheAuthor: `Are you the Author?`,
            addAuthor: `+ Add Additional Author`,
            authorsEmail: `Author's Email`,
            authorsCompany: `Author's Company`,
            authorsJobTitle: `Author's Job Title`,
            authorsLinkedInUrl: `Author's LinkedIn URL`,
            // presenter info
            presenter: `Presenter`,
            presenterUnknown: `Presenter Unknown`,
            areYouThePresenter: `Are you the Presenter?`,
            bioHelper: `Optionally provide a short Biography of the Presenter. The maximum word count is 150 words. Please do not include any Markup.`,
            presentersEmail: `Presenter's Email`,
            presentersCompany: `Presenter's Company`,
            presentersJobTitle: `Presenter's Job Title`,
            presentersLinkedInUrl: `Presenter's LinkedIn URL`,
            presentersBiography: `Presenter's Biography`,
            // thank you
            thankYou: `Thank you for submitting this Abstract`,
            submitAnother: `Submit Another`,
            // error handling
            required: `Required`,
            invalidEmail: `Invalid Email Address`,
            emailNotRegistered: `Email is not registered for this event.`,
            minimumRequired: `Minimum 3 Required`,
            // send email
            ascSubmitter: `Abstract Submission Confirmation - Submitter`,
            ascPresenterAuthor: `Abstract Submission Confirmation - Presenter/Author`,
            body1submitterA: `We have received your Abstract Submission for`,
            body1submitterB: `Please read the confirmation below.  NOTE: Each Abstract has a unique Sequence number.  Please make sure to use that Sequence number in all naming conventions for related material uploads and in any correspondence about your abstract.  In addition, all Presenters and Authors named will need to be registered for the event.`,
            body1authorPresenterA: `You have been named as a Presenter and/or Author in the below Abstract Submission for`,
            body1authorPresenterB: `Please read the confirmation below.  If you have not done so already, it is important to make sure you register at`,
            body2authorPresenter: `NOTE: Each Abstract has a unique Sequence number.  Please make sure to use that Sequence number in all naming conventions for related material uploads and in any correspondence about your abstract.  In addition, all Presenters named will need to be registered for the event.`,
            // format email
            type: `Type`,
            sequenceNumber: `Sequence Number`,
            role: `Role`,
            first_name: `First Name`,
            last_name: `Last Name`,
            job_title: `Job Title`,
            linked_in_url: `LinkedIn URL`,
            bio: 'Bio',
          },
          // here we will place our translations...
        },
      },
      fr: {
        translation: {
          navbar: {
            home: `Accueil`,
            virtualEnvironment: `Environnement \n Virtuel`,
            abstractSubmission: `Soumission \n de résumé`,
            agenda: `Agenda`,
            attendeeList: `Liste des \n Participants`,
            exhibitorList: `Liste des \n Exposants`,
            floorplan: `Plan d'étage`,
            venue: `Lieu`,
          },
          landing: {
            signIn: `S'identifier`,
            enterVE: `Entrez`,
            abstractSubmission: `Soumission de résumé`,
            agenda: `Ordre du jour`,
            attendeeList: `Liste des Participants`,
            exhibitorList: `Liste des Exposants`,
            floorplan: `Plan d'étage`,
            venue: `Lieu`,
            attendee: `Participant`,
            exhibitor: `Exposant`,
            attendeeInterests: `Intérêts des participants`,
            inPerson: `En personne`,
            virtualOnly: `Virtuel uniquement`,
            total: `Total`,
            exhTopInterest: `Exposants et sujets d'intérêt`,
            exhInterest: `Exposants d'intérêt`,
            topAttInterest: `Thèmes et nombre de participants intéressés`,
            formatHelper: `Format = Nom du sujet: nombre total d'intérêts (nombre d'intérêts sur site uniquement)`,
            eventHorizonAbout: `Le composant EventHorizon Virtual Environment ouvrira environ une semaine avant le début de l'événement. Cet environnement fournira un accès aux discussions, au réseautage et à d'autres atouts.`,
          },
          tableHeaders: {
            accountName: `Nom du compte`,
            sponsorshipLevel: `Niveau de Parrainage`,
            boothNumber: `Numéro de Stand`,
            website: `Site Internet`,
            tags: `Mots clés`,
            lastName: `Nom de famille`,
            firstName: `Prénom`,
            site: `Site`,
            department: `Département`,
            division: `Division`,
            attendance: `Présence`,
            email: `E-mail`,
            company: `Entreprise`,
            roles: `Rôle`,
          },
          registration: {
            welcomeToThe: `Bienvenue au`,
            enterEmail: `Commençons! Veuillez fournir votre adresse e-mail ci-dessous et cliquez sur suivant. \n Nous déterminerons si vous êtes déjà inscrit.`,
            next: `Suivant`,
            enter: `Entrer`,
            password: `Mot de passe`,
            resetPassword: `Réinitialiser le mot de passe`,
            inputPassword: `Veuillez entrer votre mot de passe ci-dessous pour vous connecter.`,
            forgottenPassword: `Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser ci-dessous.`,
            reset: `réinitialiser`,
            passwordWasReset: `Votre mot de passe a été réinitialisé`,
            checkEmailConfirmation: `Vérifiez votre e-mail pour un code de confirmation et entrez-le ci-dessous`,
            confirmNewPassword: `Confirmer le nouveau mot de passe`,
            confirmationCode: `Code de confirmation`,
            newPassword: `Nouveau mot de passe`,
            alrightSetUp: `Bien! Allons vous installer.`,
            pleaseFillFields: `Veuillez remplir tous les champs ci-dessous et confirmer que vous avez lu la politique de confidentialité, puis cliquez sur S'inscrire.`,
            email: 'E-mail',
            lastName: `Nom de Famille`,
            firstName: `Prénom`,
            jobTitle: `Titre d'emploi`,
            company: `Entreprise`,
            department: `Département`,
            division: `Division`,
            site: `Site`,
            addressStreet: `Adresse de la rue`,
            addressCity: `Ville`,
            addressState: `État`,
            addressZip: `Code Postal`,
            addressCountry: `Pays`,
            phoneNumber: `Numéro de téléphone`,
            privacyPolicy: `Politique de \n Confidentialité`,
            viewPrivacyPolicy: `Voir \n Politique de \n Confidentialité`,
            readPrivacyPolicy: `J'ai lu et compris la politique de confidentialité`,
            register: `Enregistrer`,
            topicsOfInterest: `Quels sujets vous intéressent ?`,
            companiesOfInterest: `Fournisseurs/Exposants d'intérêt`,
            companiesOfInterestTooltip: `Commencez à taper pour voir la liste. Si l'exposant n'est pas dans la liste, tapez-le et appuyez sur Entrée pour soumettre.`,
            attendanceQuestion: `Comment allez-vous y assister ?`,
            attendanceAnswers: {
              virtual: `Virtuel`,
              day1: `Jour 1`,
              day2: `Jour 2`,
            },
          },
          abstractSubmission: {
            abstractSubmission: `Soumission de résumé`,
            abstractInfo: `Informations Résumées`,
            abstractInformation: `Informations Résumées`,
            authorInfo: `Informations sur l'auteur`,
            authorInformation: `Informations sur l'auteur`,
            presenterInfo: `Informations sur le présentateur`,
            presenterInformation: `Informations sur le présentateur`,
            // general
            previous: `Précédent`,
            next: `Suivant`,
            submit: `Soumettre`,
            email: `E-mail`,
            optional: `Facultatif`,
            firstName: `Prénom`,
            lastName: `Nom de famille`,
            company: `Entreprise`,
            title: `Titre`,
            linkedInUrl: `URL LinkedIn`,
            biography: `Biographie`,
            yes: `Oui`,
            no: `Non`,
            // email
            yourEmail: `Votre e-mail`,
            // abstract info
            selectDesiredType: `Sélectionnez le type de résumé souhaité (Sélectionnez tout ce qui s'applique)`,
            talk: `L’exposé`,
            poster: `Affiche`,
            topic: `Sujet`,
            abstractTopic: `Sujet Résumé`,
            selectAbstractTopic: `Sélectionnez un sujet Résumé`,
            abstractTitle: `Titre`,
            yourAbstractTitle: `Votre titre de résumé`,
            abstractContent: `Contenu Résumé`,
            abstractContentHelper: `Le nombre de mots maximum est de 400 mots. Le contenu au-delà peut être coupé. Entrez du contenu sans balisage.`,
            content: `Contenu`,
            yourAbstractContent: `Votre contenu Résumé`,
            clearedForPublicView: `Autorisé pour la vue publique`,
            yesApproved: `Oui - Approuvé`,
            noApplyingApproval: `Non - Demande d'approbation`,
            noApproved: `Non`,
            otherApproved: `Autre`,
            keywordTags: `Balises de mots-clés (appuyez sur Entrée pour ajouter des balises)`,
            keywords: `Mots clés`,
            yourAbstractTags: `Vos balises Résumées`,
            // author info
            author: `Auteur`,
            areYouTheAuthor: `Êtes-vous l'auteur?`,
            addAuthor: `+${' '}Ajouter un auteur supplémentaire`,
            authorsEmail: `E-mail de l'auteur`,
            authorsCompany: `Société de l'auteur`,
            authorsJobTitle: `Titre du travail de l'auteur`,
            authorsLinkedInUrl: `URL LinkedIn de l'auteur`,
            // presenter info
            presenter: `Présentateur`,
            presenterUnknown: `Présentateur inconnu`,
            areYouThePresenter: `Êtes-vous le présentateur?`,
            bioHelper: `Fournissez éventuellement une courte biographie du présentateur. Le nombre de mots maximum est de 150 mots. Veuillez ne pas inclure de balisage.`,
            presentersEmail: `E-mail du présentateur`,
            presentersCompany: `Société du présentateur`,
            presentersJobTitle: `Titre du poste du présentateur`,
            presentersLinkedInUrl: `URL LinkedIn du présentateur`,
            presentersBiography: `Biographie du présentateur`,
            // thank you
            thankYou: `Merci d'avoir soumis ce résumé`,
            submitAnother: `Soumettre un autre`,
            // error handling
            required: `Requis`,
            invalidEmail: `Adresse e-mail invalide`,
            emailNotRegistered: `L'e-mail n'est pas enregistré pour cet événement.`,
            minimumRequired: `Minimum 3 requis`,
            // send email
            ascSubmitter: `Confirmation de soumission de résumé - Soumissionnaire`,
            ascPresenterAuthor: `Confirmation de soumission de résumé - Présentateur/Auteur`,
            body1submitterA: `Nous avons reçu votre soumission de résumé pour`,
            body1submitterB: `Veuillez lire la confirmation ci-dessous. REMARQUE: Chaque résumé a un numéro de séquence unique. Veuillez vous assurer d'utiliser ce numéro de séquence dans toutes les conventions de dénomination pour les téléchargements de matériel connexe et dans toute correspondance concernant votre résumé. De plus, tous les présentateurs et auteurs nommés devront être inscrits à l'événement.`,
            body1authorPresenterA: `Vous avez été nommé présentateur et/ou auteur dans la soumission de résumé ci-dessous pour`,
            body1authorPresenterB: `Veuillez lire la confirmation ci-dessous. Si vous ne l'avez pas déjà fait, il est important de vous assurer de vous inscrire sur`,
            body2authorPresenter: `REMARQUE: Chaque résumé a un numéro de séquence unique. Veuillez vous assurer d'utiliser ce numéro de séquence dans toutes les conventions de dénomination pour les téléchargements de matériel connexe et dans toute correspondance concernant votre résumé. De plus, tous les présentateurs nommés devront être inscrits à l'événement.`,
            // format email
            type: `Taper`,
            sequenceNumber: `Numéro de séquence`,
            role: `Rôle`,
            first_name: `Prénom`,
            last_name: `Nom de famille`,
            job_title: `Titre d'emploi`,
            linked_in_url: `URL LinkedInL`,
            bio: 'Biographie',
          },
          // here we will place our translations...
        },
      },
    },
  });

export default i18n;
