import { combineReducers } from 'redux';
import SidebarReducer from './sidebar';
import UserReducer from './user';
import BriefcaseReducer from './briefcase';
import ChatReducer from './chat';
import SnackbarReducer from './snackbar';
import DebugReducer from './debug';
import EventReducer from './event';
import TwilioReducer from './twilio';
import PosterReducer from './poster';
import AgendaReducer from './agenda';
import ExhibitReducer from './exhibit';
import TaskQueueReducer from './taskQueue';
import LanguageReducer from './language';

import { queryApi } from '../../Services/queryApi';
import StrapiReducer from './strapi';

export const rootReducer = combineReducers({
  sidebar: SidebarReducer,
  user: UserReducer,
  briefcase: BriefcaseReducer,
  chat: ChatReducer,
  snackbar: SnackbarReducer,
  debug: DebugReducer,
  event: EventReducer,
  twilio: TwilioReducer,
  strapi: StrapiReducer,
  poster: PosterReducer,
  agenda: AgendaReducer,
  exhibit: ExhibitReducer,
  taskQueue: TaskQueueReducer,
  language: LanguageReducer,

  [queryApi.reducerPath]: queryApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
