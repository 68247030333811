import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'en',
  detected: false,
};
export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setNewLanguage: (state, action) => {
      state.language = action.payload;
    },
    setDetected: (state, action) => {
      state.detected = action.payload;
    },
  },
});

// Selectors
export const getLanguage = (state) => state.language;
export const getLanguageDetected = (state) => state.detected;
// each case under reducers becomes an action
export const { setNewLanguage } = languageSlice.actions;
export const { setDetected } = languageSlice.actions;

export default languageSlice.reducer;
