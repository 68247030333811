import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';

// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import axios from 'axios';
import { demoEnabled, eventId, landingpageOnly } from '../config.js';
import { useSelector } from 'react-redux';
// import getValidUrl from '../Utilities/getValidUrl';
import Hidden from '@material-ui/core/Hidden';

// Translations
import { useTranslation } from 'react-i18next';
import EnglishFlag from '../Assets/English-Flag.png';
import FrenchFlag from '../Assets/French-Flag.png';
import setLanguage from '../Store/Actions/setLanguage';
import setLanguageDetected from '../Store/Actions/setLanguageDetected';
import { getLanguage, getLanguageDetected } from '../Store/Reducers/language';
import { useAppDispatch } from '../Store/store';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // flexGrow: 1,
    textDecoration: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      border: '1px solid #FFF',
    },
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
  },
  centerButtons: {
    textAlign: 'center',
    lineHeight: 1.25,
    height: theme.spacing(7),
    border: `1px solid ${theme.palette.primary.main}`,
    whiteSpace: 'pre-line',
    '&:hover': {
      border: '1px solid #FFF',
    },
  },
  toolbar: {
    minHeight: '64px', //required to make mobile behave properly
  },
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const currentLanguage = useSelector(getLanguage);
  const [abstractAvailable, setAbstractAvailable] = useState();
  const [strapiPages, setStrapiPages] = useState();
  const [pageButtons, setPageButtons] = useState();
  // access redux to get strapi
  const strapiPagesRedux = useSelector((state) => state.strapi);
  const userInformation = useSelector((state) => state.user);
  const eventInformation = useSelector((state) => state.event);
  // configure menu item
  const [tabIndex, setTabIndex] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // translation
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const initialize = async () => {
      await axios({
        method: 'get',
        url: 'forms/' + eventId,
        // headers: { idtoken: data.idToken.jwtToken },
        params: { showId: eventId },
      })
        .then((response) => {
          let formObject = response.data.data;
          let abstractSubmission = formObject.filter(
            (item) => item.type === 'abstract_submission'
          )[0];
          setAbstractAvailable(abstractSubmission.is_enabled);
        })
        .catch((error) => console.log(error));
      await setStrapiPages(strapiPagesRedux.strapi);
      if (!currentLanguage.detected) {
        const lng = navigator.language;
        await dispatch(setLanguage(lng));
        await dispatch(setLanguageDetected(true));
        i18n.changeLanguage(lng);
      } else {
        i18n.changeLanguage(currentLanguage.language);
      }
    };

    initialize().catch(console.error);
  }, [strapiPagesRedux.strapi, currentLanguage.language]);

  useEffect(() => {
    if (strapiPages) {
      let strapiPageButtons = (
        <>
          {strapiPages.menu_items.map((item, index) => {
            if (item.pages.length === 1) {
              // single item
              let isPublic = false; // public, no loggin necessary to view
              let isAuthenticated = false; // loggin necessary, any role can view
              let hasNecessaryRole = false; // role specific routes based on show attendee role
              item.pages[0].visitor_roles.forEach((role) => {
                if (role.toLowerCase() === 'public') {
                  isPublic = true;
                }
                if (
                  role.toLowerCase() === 'authenticated' &&
                  userInformation.user
                ) {
                  isAuthenticated = true;
                }
                if (
                  userInformation?.user?.roles
                    ?.toLowerCase()
                    ?.includes(role.toLowerCase())
                ) {
                  hasNecessaryRole = true;
                }
              });
              if (index === 0) {
                return null;
              }
              if (isPublic || isAuthenticated || hasNecessaryRole) {
                let linkToPage = `/${item.pages[0].slug}`;
                if (item.pages[0].slug.includes('redirect')) {
                  // link to outside website
                  linkToPage = item.pages[0].content
                    .replace('<p>', '')
                    .replace('</p>', '');
                  return (
                    <Button
                      key={index}
                      className={classes.centerButtons}
                      color="inherit"
                      href={linkToPage}
                    >
                      {item.pages[0].label
                        ? item.pages[0].label
                        : item.pages[0].title}
                    </Button>
                  );
                }
                // link to another internal strapi page
                let label = '';
                switch (item.pages[0].label) {
                  case 'Agenda':
                    label = t('navbar.agenda');
                    break;
                  case 'Attendee List':
                    label = t('navbar.attendeeList');
                    break;
                  case 'Exhibitor List':
                    label = t('navbar.exhibitorList');
                    break;
                  case 'Floorplan':
                    label = t('navbar.floorplan');
                    break;
                  case 'Venue':
                    label = t('navbar.venue');
                    break;
                  default:
                    break;
                }
                return (
                  <Button
                    key={index}
                    className={classes.centerButtons}
                    color="inherit"
                    component={Link}
                    to={linkToPage}
                  >
                    {/* {item.pages[0].label
                      ? item.pages[0].label
                      : item.pages[0].title} */}
                    {label}
                  </Button>
                );
              } else {
                return null;
              }
            } else {
              // multiple items
              const dropdownMenuItems = [];
              item.pages.forEach((page) => {
                let isPublic = false; // public, no loggin necessary to view
                let isAuthenticated = false; // loggin necessary, any role can view
                let hasNecessaryRole = false; // role specific routes based on show attendee role
                page.visitor_roles.forEach((role) => {
                  if (role.toLowerCase() === 'public') {
                    isPublic = true;
                  }
                  if (
                    role.toLowerCase() === 'authenticated' &&
                    userInformation.user
                  ) {
                    isAuthenticated = true;
                  }
                  if (
                    userInformation?.user?.roles
                      ?.toLowerCase()
                      ?.includes(role.toLowerCase())
                  ) {
                    hasNecessaryRole = true;
                  }
                });
                if (isPublic || isAuthenticated || hasNecessaryRole) {
                  let linkToPage = `/${page.slug}`;
                  if (page.slug.includes('redirect')) {
                    // link to outside website
                    linkToPage = page.content;
                    dropdownMenuItems.push(
                      <Button href={linkToPage}>
                        <Typography
                          style={{
                            textTransform: 'initial',
                            paddingLeft: '8px',
                          }}
                        >
                          {page.label ? page.label : page.title}
                        </Typography>
                      </Button>
                    );
                  } else {
                    dropdownMenuItems.push(
                      <MenuItem
                        onClick={handleClose}
                        component={Link}
                        to={linkToPage}
                        key={linkToPage}
                      >
                        {page.label ? page.label : page.title}
                      </MenuItem>
                    );
                  }
                }
              });
              if (dropdownMenuItems.length) {
                return (
                  <div key={index} onClick={() => setTabIndex(index)}>
                    <Button
                      id="basic-button"
                      color="inherit"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    >
                      {item.label}
                      <ChevronRightIcon
                        style={{ transform: 'rotateZ(90deg)' }}
                      />
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open && tabIndex === index}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      {dropdownMenuItems}
                    </Menu>
                  </div>
                );
              } else {
                return null;
              }
            }
          })}
        </>
      );
      setPageButtons(strapiPageButtons);
    }
  }, [
    anchorEl,
    classes.centerButtons,
    open,
    strapiPages,
    tabIndex,
    userInformation.user,
    currentLanguage.language,
  ]);

  // const lng = navigator.language;
  return (
    <AppBar position="fixed" className={clsx(classes.appBar)}>
      <Toolbar variant="regular" className={classes.toolbar}>
        <Button className={classes.title} component={Link} to={'/'}>
          <Typography variant="h6" style={{ color: '#FFF' }}>
            {t('navbar.home')}
          </Typography>
        </Button>
        <div style={{ flexGrow: 1 }} />
        <Hidden xsDown>
          <>{pageButtons}</>
        </Hidden>
        {abstractAvailable &&
          userInformation.user &&
          userInformation.user !== 'unregistered' &&
          userInformation?.user?.roles?.includes('Attendee') && (
            <Button
              className={classes.centerButtons}
              color="primary"
              variant="contained"
              component={Link}
              to={'/abstractsubmission'}
              disableElevation
            >
              {t('navbar.abstractSubmission')}
            </Button>
          )}
        {/* {!demoEnabled && !landingpageOnly && (
          <Button
            className={classes.centerButtons}
            color="inherit"
            component={Link}
            to={'/registration'}
          >
            {eventInformation.event.under_construction ? 'Register' : 'Enter'}
          </Button>
        )} */}
        {landingpageOnly && (
          <Button
            className={classes.centerButtons}
            color="inherit"
            component={Link}
            to={'/eventhorizon'}
            disableElevation
          >
            {/* Virtual <br />
            Environment */}
            {t('navbar.virtualEnvironment')}
          </Button>
        )}
        {demoEnabled && (
          <Button
            className={classes.centerButtons}
            color="inherit"
            component={Link}
            to={'/registration'}
            disableElevation
          >
            Demo <br />
            Environment
          </Button>
        )}
        <div style={{ height: '64px' }}>
          <Button
            disableElevation
            size="small"
            style={{ padding: '0px' }}
            onClick={() => {
              dispatch(setLanguage('fr'));
              i18n.changeLanguage('fr');
            }}
          >
            <img
              src={FrenchFlag}
              alt="English"
              style={{ width: '32px', padding: '0px' }}
            />
          </Button>
          <br />
          <Button
            disableElevation
            size="small"
            style={{ padding: '0px' }}
            onClick={() => {
              dispatch(setLanguage('en'));
              i18n.changeLanguage('en');
            }}
          >
            <img
              src={EnglishFlag}
              alt="English"
              style={{ width: '32px', padding: '0px' }}
            />
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}
