import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

import { useEffect, useState } from 'react';

import { companies } from './LookUpFields';

// Translations
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}));

const CompanyLookupField = ({ value = '', onSelect }) => {
  const classes = useStyles();
  const [data] = useState(companies.map((company) => company.account_name));
  const [term, setTerm] = useState(value);
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [empty, setEmpty] = useState(false);

  // translation
  const { t } = useTranslation();

  useEffect(() => {
    if (empty) {
      setSelectedValues([]);
    }
    onSelect(selectedValues);
    setEmpty(false);
  }, [empty, selectedValues]);

  return (
    <Autocomplete
      className={classes.root}
      autoComplete={true}
      id="data"
      multiple
      freeSolo={true}
      getOptionLabel={(data) => data}
      options={data}
      inputValue={term}
      open={open}
      onOpen={() => {
        if (term) {
          setOpen(true);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(event, value) => {
        setSelectedValues(value);
      }}
      onInputChange={(e, value) => {
        // setValue(name, value);
        setTerm(value);

        // only open when inputValue is not empty after the user typed something
        if (!value) {
          setOpen(false);
        }
      }}
      noOptionsText={'No Data'}
      renderTags={(values, getTagProps) => {
        return values.map((value, index) => {
          let chipProps = { ...getTagProps({ index }) };
          return (
            <Chip
              color="primary"
              key={value}
              label={value}
              {...getTagProps({ index })}
              onDelete={() => {
                chipProps.onDelete();
                if (values?.length === 1) {
                  setEmpty(true);
                }
              }}
            />
          );
        });
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="filled"
            label={t('registration.companiesOfInterest')}
            placeholder={t('registration.companiesOfInterestTooltip')}
          />
        );
      }}
    />
  );
};

export default CompanyLookupField;
